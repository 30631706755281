<template>
  <div v-frag>
    <v-container class="py-10">
      <v-row align="center" justify="end" class="mx-0">
        <v-col cols="12" sm="6">
          <v-btn color="primary" @click="openCategoryDialog">
            {{ $t('button.addNew') }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-text-field
            v-model="search"
            aria-label="Keresés"
            label="Keresés"
            filled
            prepend-inner-icon="mdi-magnify"
            persistent-hint
            clearable
            hint="Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="categories"
        :search="search"
        :loading="loadingData"
        loading-text="Adatok betöltése folyamatban... Kérjük várjon!"
        no-data-text="Nincsenek megjeleníthető kategóriák!"
        :no-results-text="`A keresett ${search} kifejezés nem található`"
        :items-per-page="25"
      >
        <template v-slot:item.isActive="{item}">
          <v-chip :color="item.isActive === 1 ? 'success': 'warning'" small>
            {{ item.isActive === 1 ? $t('active') : $t('notActive') }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{item}">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      @click="editCategory(item)"
                      v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>Kategória szerkesztése</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <!-- KATEGÓRIA SZERKESZTÉSE START -->
    <v-dialog v-model="categoryDialog.isVisible" scrollable persistent max-width="640">
      <v-card>
        <v-card-title class="indigo">
            <span class="white--text">
              {{ categoryDialog.content.id ? $t('updateCategory') : $t('createCategory') }}
            </span>
          <v-spacer></v-spacer>
          <v-icon @click="closeCategoryDialog" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-form v-model="categoryDialog.categoryForm">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="categoryDialog.content.translation.name"
                              :label="$t('labels.name')"
                              :rules="[$store.state.inputRules.requiredField]"
                              :hint="$t('hints.requiredField')"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="categoryDialog.content.code"
                              :label="$t('labels.code')"
                              :rules="[$store.state.inputRules.codeValidatorLowerCase]"
                              :hint="$t('hints.optionalFieldCode')"
                              append-icon="mdi-pencil"
                              @click:append="convertCategoryCode(categoryDialog.content.translation.name)"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="categoryDialog.content.root"
                          :items="selectfieldOptions.categories"
                          item-text="translation.name"
                          item-value="id"
                          :label="$t('labels.parentCategory')"
                          :hint="$t('hints.optionalField')"
                          persistent-hint
                          clearable
                          filled>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-spacer></v-spacer>
          <v-btn text @click="closeCategoryDialog" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveCategory" :disabled="!categoryDialog.categoryForm">
            {{ categoryDialog.content.id ? 'Frissítés' : 'Mentés' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- KATEGÓRIA SZERKESZTÉSE END -->
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </div>
</template>

<script>
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';

export default {
  name: 'PageBuilderCategories',
  components: {
    LoadingAndResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    this.categories = await this.loadTableData();
    this.loadingData = false;
  },
  data() {
    return {
      search: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Név', value: 'translation.name' },
        { text: 'Kód', value: 'code' },
        { text: 'Szülő kategória', value: 'root' },
        { text: 'Státusz', value: 'isActive' },
        { text: '', value: 'actions' },
      ],
      categories: [],
      loadingData: true,
      categoryDialog: {
        isVisible: false,
        categoryForm: false,
        content: {
          id: null,
          root: null,
          code: null,
          isActive: 1,
          translation: {
            language: 'hu',
            name: null,
          },
        },
      },
      selectfieldOptions: {
        categories: [],
      },
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'Bejelentkezés folyamatban.',
        successText: 'Sikeres bejelentkezés!',
        errorText: 'Sikertelen bejelentkezés!',
        successBtnText: 'Rendben',
        errorBtnText: 'Rendben',
      },
    };
  },
  methods: {
    async loadTableData() {
      const response = await this.$store.dispatch('showCategories');
      // console.log('PageBuilderCategories tabledata: ', response);
      if (response.status === 200) {
        const sortedList = response.data.sort((a, b) => ((a.translation.name > b.translation.name) ? 1 : -1));
        this.selectfieldOptions.categories = sortedList;
        return sortedList;
      }
      return [];
    },
    editCategory(category) {
      this.categoryDialog.content.id = category.id;
      this.categoryDialog.content.root = category.root;
      this.categoryDialog.content.code = category.code;
      this.categoryDialog.content.isActive = category.isActive;
      this.categoryDialog.content.translation.name = category.translation.name;
      this.categoryDialog.categoryForm = true;
      this.openCategoryDialog();
    },
    async saveCategory() {
      this.openLARDialog();
      if (!this.categoryDialog.content.code || this.categoryDialog.content.code.length < 1) {
        await this.convertCategoryCode(this.categoryDialog.content.translation.name);
      }
      let response = null;
      if (this.categoryDialog.id) {
        // ha van id, akkor frissítés
        response = await this.$store.dispatch('updateCategory', this.categoryDialog.content);
      } else {
        // ha nincs id, akkor új létrehozása
        response = await this.$store.dispatch('createCategory', this.categoryDialog.content);
      }
      if (response.status === 200) {
        if (this.categoryDialog.id) {
          this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.CATEGORY_UPDATED';
        } else {
          this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.CATEGORY_CREATED';
        }
        setTimeout(this.changeLARDialogContentToSuccess, 2200);
      } else {
        if (this.categoryDialog.id) {
          this.loadingAndResponseDialog.successText = 'ERROR_MESSAGES.CATEGORY_UPDATED';
        } else {
          this.loadingAndResponseDialog.successText = 'ERROR_MESSAGES.CATEGORY_CREATED';
        }
        setTimeout(this.changeLARDialogContentToError, 2200);
      }
    },
    async convertCategoryCode(stringToConvert) {
      this.categoryDialog.content.code = await this.$store.dispatch('convertToSlug', stringToConvert);
    },
    openCategoryDialog() {
      this.categoryDialog.isVisible = true;
    },
    closeCategoryDialog() {
      this.categoryDialog = {
        isVisible: false,
        categoryForm: false,
        content: {
          id: null,
          root: null,
          code: null,
          isActive: 1,
          translation: {
            language: 'hu',
            name: null,
          },
        },
      };
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
      window.location.reload();
    },
    onErrorClickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
    },
  },
  watch: {
    //
  },
};
</script>

<style scoped>

</style>
